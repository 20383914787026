<template>
  <div>
    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-dolly-flatbed </v-icon>
        <v-toolbar-title class="pl-4">Log integração - Itens com falha</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Inicio filtros -->
        <v-row align="center">
          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_inicio"
              v-model="modal.data_inicio"
              :return-value.sync="dataInicio"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data inicial"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateInicioGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataInicio" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_inicio = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_inicio.save(dataInicio)">OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_final"
              v-model="modal.data_final"
              :return-value.sync="dataFinal"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data final"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateFinalGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataFinal" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.data_final = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_final.save(dataFinal)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="1"
            style="padding: 0 0 0 12px !important; max-width: 50px !important">
            <v-app-bar-nav-icon @click="changePage(1)">
              <v-icon>fas fa-search</v-icon>
            </v-app-bar-nav-icon>
          </v-col>
          <v-col
            cols="3"
            class="px-6 py-6"
          >
            <v-text-field
              dense
              class="alinhamento"
              v-model="search"
              label="Pesquisar"
              type="text"
              autocomplete="off"
              clearable
            ></v-text-field>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            cols="2"
            style="display: flex; justify-content: end;"
          >
            <v-btn
              color="success"
              @click="reprocessAll"
              >Reprocessar
            </v-btn>
          </v-col>
        </v-row>
        <!-- Final filtros -->

        <!-- Inicio cards Indicadores -->
        <v-row class="mt-2" style="margin-left: 8px;">
            <v-col class="pl-0">
              <v-text-field
                dense
                outlined
                readonly
                label="Total Pedidos"
                v-model="indicadores.total_pedidos"
                type="text"
                style="width: 200px"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                dense
                outlined
                readonly
                label="Pedidos"
                v-model="indicadores.pedidos"
                type="text"
                style="width: 200px"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                dense
                outlined
                readonly
                label="Cotação com sucesso"
                v-model="indicadores.cotacao_sucesso"
                type="text"
                style="width: 200px"

              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                dense
                outlined
                readonly
                label="Cotação com falha"
                v-model="indicadores.cotacao_falha"
                type="text"
                style="width: 200px"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                readonly
                label="Total Embalamento"
                v-model="indicadores.total_embalamento"
                type="text"
                style="width: 200px"

              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                dense
                outlined
                readonly
                label="Total Objetos"
                v-model="indicadores.total_objetos"
                type="text"
                style="width: 200px"
              ></v-text-field>
            </v-col>

        </v-row>
        <!-- Final cards Indicadores-->

    <!-- Inicio Principal -->
        <v-row
            class="mt-4"
        >
          <v-col>
              <v-pagination
                  v-model="pagination.page"
                  :length="Math.ceil(totalSize / pagination.size )"
                  @input="changePage"
                  :total-visible="5"
              ></v-pagination>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
            dense
            :headers="grid.logs.cabecalho"
            :items="grid.logs.items" sortBy="dataPedido" update: sort-desc
            :key="refreshGrid"
            :search="search"
            @current-items="currentItems"
            height="55vh"
            fixed-header
            hide-default-footer class="elevation-1"
            :loading="grid.logs.carregando"
            :items-per-page="grid.logs.porPagina"
            show-select
            item-key="numeroDoPedido"
            v-model="selected">
            <template #item.transportadoraNome="{ item }">
                <div
                  style="display: flex; align-items: center;"
                >
                  <td style="cursor: pointer;" @click="alterarTransportadoraServicoEntrega(item)">
                    {{ item.transportadoraNome }}
                  </td>
                </div>
              </template>
              <template #item.servicoEntrega="{ item }">
                <div
                  style="display: flex; align-items: center;"
                >
                  <td style="cursor: pointer;" @click="alterarTransportadoraServicoEntrega(item)">
                    {{ item.servicoEntrega }}
                  </td>
                </div>
              </template>
              <!-- Inicio badge  Envio Objeto-->
              <template #item.enviadoObjeto="{ item }">
                <div
                  style="display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColor(item.enviadoObjeto)" @click="loadLogPedido(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge  Envio Objeto-->

              <!-- Inicio badge Pedido-->
              <template #item.pedido="{ item }">
                <div
                  style="display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColor(item.pedido)" @click="loadLogPedido(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge  Pedido-->

              <!-- Inicio badge Pedido-->
              <template #item.btnReprocessar="{ item }">
                <div
                  style="display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-btn x-small color="success" @click="reprocessarItem(item)"
                      >Reprocessar</v-btn
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge  Pedido-->

              <!-- Inicio badge Rastreio-->
              <template #item.rastreio="{ item }">
                <div
                  style="display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColor(item.rastreio)" @click="loadLogPedido(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge Rastreio-->

              <!-- Inicio badge Cotacao-->
              <template #item.cotacao="{ item }">
                <div
                  style="display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColorCotacao(item)" @click="loadLogPedido(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge Cotacao-->

              <!-- Inicio badge Sisfrete Embalamento-->
              <template #item.sisfreteEmbalamento="{ item }">
                <div
                  style="display: flex; align-items: center; justify-content: center;"
                >
                  <td>
                    <v-icon size="16" :color="getColor(item.sisfreteEmbalamento)" @click="loadLogPedido(item)"
                      >fa-solid fa-circle</v-icon
                    >
                  </td>
                </div>
              </template>
              <!-- Final badge Sisfrete Embalamento-->

            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right mr-3">
            <v-btn
              :disabled="selected.length == 0"
              color="error"
              @click="deleteAll"
              >Excluir</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal  -->

    <!-- Início modal log pedido -->
    <v-dialog
      v-model="modal.log_pedido"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar color="#018656" dark dense fixed>
          <v-toolbar-title class="pl-4">
            Log Pedido
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="closeModalLogPedido()">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>
        <v-card-text class="mt-2 px-2">
          <div>
            <v-card>
              <v-toolbar flat>
                <v-toolbar-title class="pl-4">Pedido nº {{ dataModalLogPedido.codigoPedidoAbacos }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  @click="reprocessar"
                  >Reprocessar</v-btn
                >
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Data Venda:</b> {{ dataModalLogPedido.dataVenda }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Status:</b> {{ dataModalLogPedido.statusPedido }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Chave NFE:</b> {{ dataModalLogPedido.chaveNFE }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Número Nota:</b> {{ dataModalLogPedido.numeroNota }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>CEP:</b> {{ dataModalLogPedido.destinatarioCep }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Número Rastreio:</b> {{ dataModalLogPedido.numeroObjeto }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Transportadora:</b> {{ dataModalLogPedido.transportadoraNome }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Serviço Entrega:</b> {{ dataModalLogPedido.servicoEntrega }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Canal:</b> {{ dataModalLogPedido.canalVenda }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Subcanal:</b> {{ dataModalLogPedido.subCanal }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Base:</b> {{ dataModalLogPedido.base }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Código Serviço:</b> {{ dataModalLogPedido.codigoServico }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Valor Pedido:</b> {{ dataModalLogPedido.valorPedido }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>Valor Frete:</b> {{ dataModalLogPedido.valorFrete }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 10px; padding-top: 0px;">
                  <b>Mensagem:</b> {{ dataModalLogPedido.mensagem }}
                </v-col>
                <v-divider></v-divider>
                <v-row class="modalConferencia mt-2">
                  <v-col class="col-12">
                    <v-card>
                      <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0">
                        Produtos
                      </v-card-title>
                      <v-card-text class="mt-4">
                        <v-data-table
                          dense
                          :headers="this.grid.produtos.cabecalho"
                          :items="this.grid.produtos.items"
                          hide-default-footer
                          class="elevation-1"
                          item-key="codigo"
                        >
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="modalConferencia mt-2">
                  <v-col class="col-12">
                    <v-card>
                      <v-card-title class="text-h7 primary" style="color: white; height: 35px; padding: 0">
                        <v-spacer></v-spacer>
                        <div style="margin-left: 150px">
                          Cotação - CEP destino: {{ dadosCotacao.cepDestino }} - Token: {{ dadosCotacao.token }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn @click="modalRequisicaoCatacao = true" style="height: 27px; color: #1976D2; margin-right: 10px">Requisição</v-btn>
                      </v-card-title>
                      <v-card-text class="mt-4">
                        <v-data-table
                          dense
                          :headers="this.grid.cotacao.cabecalho"
                          :items="this.grid.cotacao.items"
                          hide-default-footer
                          class="elevation-1"
                          item-key="codigo"
                        >
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="modalConferencia mt-2">
                  <v-col class="col-12">
                    <v-card>
                      <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0">
                        Retorno Cotação
                      </v-card-title>
                      <v-card-text class="mt-4">
                        <v-data-table
                          dense
                          :headers="this.grid.retornoCotacao.cabecalho"
                          :items="this.grid.retornoCotacao.items"
                          hide-default-footer
                          class="elevation-1"
                          item-key="codigo"
                        >
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="modalConferencia mt-2">
                  <v-col class="col-12">
                    <v-card>
                      <v-card-title class="text-h7 primary" style="color: white; height: 35px; padding: 0;">
                        <v-spacer></v-spacer>
                        <div style="margin-left: 150px">
                          Embalamento - CEP destino: {{ dadosEmbalamento.cepDestino }} - Token: {{ dadosEmbalamento.token }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn @click="modalRequisicaoEmbalamento = true" style="height: 27px; color: #1976D2; margin-right: 10px">Requisição</v-btn>
                      </v-card-title>
                      <v-card-text class="mt-4">
                        <v-data-table
                          dense
                          :headers="this.grid.produtosEmbalamento.cabecalhoUS"
                          :items="this.grid.produtosEmbalamento.items"
                          hide-default-footer
                          class="elevation-1"
                          item-key="codigo"
                        >
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="modalConferencia mt-2">
                  <v-col class="col-12">
                    <v-card>
                      <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0">
                        Retorno Embalamento
                      </v-card-title>
                      <v-card-text class="mt-4">
                        <v-data-table
                          dense
                          :headers="this.grid.retornoEmbalamento.cabecalho"
                          :items="this.grid.retornoEmbalamento.items"
                          hide-default-footer
                          class="elevation-1"
                          item-key="codigo"
                        >
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row class="modalConferencia mt-2">
                  <v-col class="col-12">
                    <v-card>
                      <v-card-title class="text-h7 primary" style="color: white; justify-content: center; height: 35px; padding: 0">
                        Status
                      </v-card-title>
                      <v-card-text class="mt-4">
                        <v-row>
                          <v-col cols="12" style="padding-bottom: 0px">
                            <b>Número do pedido:</b> {{ dadosStatus.numeroDoPedido }}
                          </v-col>
                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                            <b>Código pedido ERP:</b> {{ dadosStatus.codigoPedidoAbacos }}
                          </v-col>
                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                            <b>Status:</b> {{ dadosStatus.statusPedido }}
                          </v-col>
                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                            <b>Emissão nota:</b> {{ dadosStatus.dataEmissaoNota }}
                          </v-col>
                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                            <b>Série nota:</b> {{ dadosStatus.serieNota }}
                          </v-col>
                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                            <b>Número nota:</b> {{ dadosStatus.numeroNota }}
                          </v-col>
                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                            <b>Chave nfe:</b> {{ dadosStatus.chaveNfe }}
                          </v-col>
                          <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                            <b>Código rastreio:</b> {{ dadosStatus.numeroObjeto }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-divider></v-divider>

                <!-- <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b> JÁ FOI-> PRODUTOS:</b> {{ dataModalLogPedido.jsonProdutos }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>JÁ FOI-> COTACAO:</b> {{ dataModalLogPedido.jsonSisfreteRequisicao }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>JÁ FOI-> RETORNO COTACAO:</b> {{ dataModalLogPedido.jsonSisfrete }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>JÁ FOI-> EMBALAMENTO:</b> {{ dataModalLogPedido.jsonSisfreteEmbalamentoRequisicao }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>JÁ FOI-> RETORNO EMBALAMENTO:</b> {{ dataModalLogPedido.jsonSisfreteEmbalamento }}
                </v-col>
                <v-col cols="12" style="padding-bottom: 0px; padding-top: 0px;">
                  <b>JÁ FOI-> STATUS:</b> {{ dataModalLogPedido.jsonStatus }}
                </v-col> -->
              </v-card-text>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim modal log pedido -->

    <!-- Modal modalAlterarTransportadoraServico -->
    <v-dialog v-model="modalAlterarTransportadoraServico" max-width="800">
      <v-card>
        <v-card-title>Alterar - Transportadora e Serviço de entrega</v-card-title>

        <v-container>
          <v-form>
            <v-row>
              <v-col>
                <v-label>Código Pedido</v-label>
                <v-text-field
                  v-model="dadosModal.codigoPedidoAbacos"
                  label="Código Pedido"
                  readonly
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-label>Transportadora</v-label>
                <v-text-field
                  v-model="dadosModal.transportadoraNome"
                  readonly
                  solo
                ></v-text-field>
              </v-col>
              <v-col>
                <v-label>Serviço de Entrega</v-label>
                <v-text-field
                  v-model="dadosModal.servicoEntrega"
                  readonly
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-label>Selecione a Transportadora - Serviço de entrega</v-label>
                <v-select
                  :items="transportadoraServicoEntrega"
                  label="Selecione"
                  solo
                  @change="changeTransportadoraServicoEntrega"
                  v-model="transportadoraServicoEntregaSelecionado"
                  item-text="descricao"
                  item-value="codigo"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions style="display: flex; justify-content: flex-end;">
          <v-btn
            class="ma-2"
            color="error"
            @click="modalAlterarTransportadoraServico = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="ma-2"
            color="primary"
            @click="gravarTransportadoraServicoEntrega"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim modal modalAlterarTransportadoraServico -->

    <!-- Inicio Loader reprocessar -->
    <v-dialog v-model="loadingReprocessar" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text style="padding-top: 7px">
          {{ firstTime ? 'Carregando...' : 'Reprocessando...' }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Loader reprocessar -->

    <!-- inicio modal requisicao  -->
    <v-dialog
      v-model="modalRequisicaoCatacao"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Json Cotação
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modalRequisicaoCatacao = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title>

        <pre id="jsonCotacao" style="margin-top: 20px !important">
          {{ json.cotacao }}
        </pre>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="copiarJsonCotacao()"
          >
            Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalRequisicaoEmbalamento"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Json Embalamento
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="modalRequisicaoEmbalamento = false">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title>

        <pre id="jsonEmbalamento" style="margin-top: 20px !important">
          {{ json.embalamento }}
        </pre>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="copiarJsonEmbalamento()"
          >
            Copiar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fim modal requisicao -->

  </div>
</template>

<script>
import { baseApiUrlPersys, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";

export default {
  name: "Entregues",
  data: () => ({
    selected: [],
    codigo_empresa: '',
    dataInicio: moment (new Date(new Date().getTime()).setDate(new Date().getDate() - 7)).format("YYYY-MM-DD"),
    dataFinal: moment(new Date()).format("YYYY-MM-DD"),
    search: "",
    refreshGrid: false,
    loadingReprocessar: false,

    modalRequisicaoCatacao: false,
    modalRequisicaoEmbalamento: false,
    modalAlterarTransportadoraServico: false,

    transportadoraServicoEntrega: [],
    transportadoraServicoEntregaSelecionado: {},

    dadosModal: {
      numeroDoPedido: '',
      codigoPedidoAbacos: '',
      transportadoraNome: '',
      servicoEntrega: '',
      base: '',
      codigoTransportadoraSisfrete: '',
    },

    json: {
      cotacao: [],
      embalamento: [],
    },


    grid: {
      produtos: {
        cabecalho: [
          { text: "Código", value: "codigo" },
          { text: "Quantidade", value: "quantidade", align: "right" },
          { text: "Altura", value: "altura", align: "right" },
          { text: "Largura", value: "largura", align: "right" },
          { text: "Comprimento", value: "comprimento", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Preço", value: "valor", align: "right" },
        ],
        items: [],
      },
      cotacao: {
        cabecalho: [
          { text: "Código", value: "sku" },
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
          { text: "Quantidade", value: "quantity", align: "right" },
        ],
        items: [],
      },
      retornoCotacao: {
        cabecalho: [
          { text: "Base", value: "Base" },
          { text: "Custo", value: "custo", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo Transp", value: "prazo_transporte", align: "right" },
          { text: "Aliq. acresc. token", value: "aliquota_acrescimo_token", align: "right" },
          { text: "Base acresc. token", value: "base_acrescimo_token", align: "right" },
          { text: "Valor acresc. token", value: "valor_acrescimo_token", align: "right" },
          { text: "Base difal", value: "base_difal", align: "right" },
          { text: "Valor difal", value: "valor_difal" },
          { text: "Valor produto difal", value: "valor_produto_difal", align: "right" },
          { text: "Aliquota difal", value: "aliquota_difal", align: "right" },
          { text: "Tipo difal", value: "tipo_difal", align: "right" },
          { text: "Base calc. adicional", value: "base_calc_adicional", align: "right" },
        ],
        items: [],
      },
      produtosEmbalamento: {
        cabecalho: [
          { text: "Altura", value: "height", align: "right" },
          { text: "Largura", value: "width", align: "right" },
          { text: "Comprimento", value: "length", align: "right" },
          { text: "Peso", value: "weight", align: "right" },
          { text: "Preço", value: "price", align: "right" },
          { text: "Quantidade", value: "quantity", align: "right" },
        ],
        items: [],
      },
      retornoEmbalamento: {
        cabecalho: [
          { text: "Base", value: "Base"},
          { text: "Custo", value: "custo", align: "right" },
          { text: "Valor frete", value: "valor_frete", align: "right" },
          { text: "Peso", value: "peso", align: "right" },
          { text: "Prazo transporte", value: "prazo_transporte", align: "right" }
        ],
        items: [],
      },


      logs: {
        cabecalho: [
          { text: "Reprocessar", value: "btnReprocessar", sortable: false },
          {
            text: "Código Pedido",
            value: "codigoPedidoAbacos",
            sortable: false,
          },
          { text: "Transportadora", value: "transportadoraNome", sortable: false },
          { text: "Serviço de Entrega", value: "servicoEntrega", sortable: false },
          { text: "Canal", value: "canalVenda", sortable: false },
          { text: "Subcanal", value: "subCanal", sortable: false },
          { text: "Base", value: "base", sortable: false },
          { text: "Número Nota", value: "numeroNota", sortable: false },
          { text: "Data Venda", value: "dataVenda", sortable: false },
          // { text: "Data Pedido", value: "dataPedido", sortable: false },
          { text: "Mensagem", value: "mensagem", sortable: false },
          { text: "Enviado Objeto", value: "enviadoObjeto", sortable: false, align: "center" },
          { text: "Pedido", value: "pedido", sortable: false, align: "center" },
          { text: "Rastreio", value: "rastreio", sortable: false, align: "center" },
          { text: "Cotação", value: "cotacao", sortable: false, align: "center" },
          { text: "Embalamento", value: "sisfreteEmbalamento", sortable: false, align: "center"},
        ],
        items: [],
        itemsAxus: [],
        carregando: false,
        porPagina: 10,
      },
    },
    dadosStatus: [],
    dadosCotacao: {
      cepDestino: '',
      token: '',
    },
    dadosEmbalamento: {
      cepDestino: '',
      token: '',
    },
    modal: {
      data_inicio: false,
      data_final: false,
      log_pedido: false,
    },
    logsBkp: [],
    fieldFilter: "",
    dataModalLogPedido: {
      pedidoCanalVenda: '',
      numeroDoPedido: '',
      codigoPedidoAbacos: '',
      dataVenda: '',
      statusPedido: '',
      chaveNFE: '',
      numeroNota: '',
      numeroObjeto: '',
      transportadoraNome: '',
      servicoEntrega: '',
      canalVenda: '',
      subCanal: '',
      base: '',
      codigoServico: '',
      valorPedido: '',
      valorFrete: '',
      mensagem: '',
      jsonProdutos: '',
      jsonSisfreteRequisicao: '',
      jsonSisfrete: '',
      jsonSisfreteEmbalamentoRequisicao: '',
      jsonSisfreteEmbalamento: '',
      jsonStatus: '',
      destinatarioCep: '',
    },
    indicadores: {
      total_pedidos: "",
      pedidos: "",
      cotacao_sucesso: "",
      cotacao_falha: "",
      total_embalamento: "",
      total_objetos: ""
    },
    pagination: {
      size: 50,
      page: 1,
    },
    base_transp: '',
    totalSize: '',
    reprocessou: false,
    firstTime: true,
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    await this.carregarLogs();
    await this.consultaTransportadorasServicosEntrega();
  },

  computed: {
    formatDateInicio() {
      return this.filtros.data_inicio
        ? moment(this.filtros.data_inicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinal() {
      return this.filtros.data_final
        ? moment(this.filtros.data_final).format("DD/MM/YYYY")
        : "";
    },
    formatDateInicioGrid() {
      return this.dataInicio
        ? moment(this.dataInicio).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinalGrid() {
      return this.dataFinal ? moment(this.dataFinal).format("DD/MM/YYYY") : "";
    },
  },

  methods: {

    copiarJsonCotacao(){
      // Get the text field
      var copyText = document.getElementById("jsonCotacao");
      console.log(copyText)
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.innerHTML);

    },
    copiarJsonEmbalamento(){
      // Get the text field
      var copyText = document.getElementById("jsonEmbalamento");
      console.log(copyText)
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.innerHTML);

    },

    async reprocessarItem(item){
      this.loadingReprocessar = true;
      await axios
        .get(`${baseApiUrlPersys}/order/calcular_frete/force/numero_pedido/${item.numeroDoPedido}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if(res.status == 200) {
            this.loadingReprocessar = false;
            this.modal.log_pedido = false;
            this.carregarLogs();
            showSuccess("Pedido reprocessado com sucesso!");
          } else {
            showError("Pedido não reprocessado!");
          }
        }).catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.loadingReprocessar = false;
        });
    },


    currentItems(value){
      this.grid.logs.itemsAxus = value
    },

    getColor(item) {
      if (item == true) {
        return "#3CA40B";
      } else if (item == false) {
        return "#FAB913";
      } else {
        return "#a1a0a0"
      }
    },

    getColorCotacao(item) {
      if (item.cotacao == 1) {
        return "#3CA40B";
      } else if (item.cotacao == 0) {
        return "#FAB913";
      } else if (item.cotacao == 2) {
        return "#FF0000";
      }else {
        return "#a1a0a0"
      }
    },

    async reprocessarGrid(){
      this.loadingReprocessar = true;
      for (let i = 0; i < this.grid.logs.itemsAxus.length; i++) {
        await this.funcaoReprocessar(this.grid.logs.itemsAxus[i].numeroDoPedido);
        console.log(i);
      }
      this.loadingReprocessar = false;
      this.firstTime = false;
      this.search = "";
      await this.carregarLogs()
      showSuccess("Pedido reprocessado com sucesso!");
    },

    async funcaoReprocessar(pedidoCanalVenda){
      await axios
      .get(
        `${baseApiUrlPersys}/order/calcular_frete/${pedidoCanalVenda}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
      )
    },

    async carregarLogs() {
      this.grid.logs.carregando = true;
      this.grid.logs.items = [];
      this.grid.logs.itemsAxus = [];
      this.indicadores.total_pedidos = "",
      this.indicadores.pedidos = "",
      this.indicadores.cotacao_sucesso = "",
      this.indicadores.cotacao_falha = "",
      this.indicadores.total_embalamento = "",
      this.indicadores.total_objetos = "",
      await axios
        .get(`${baseApiUrlPersys}/order/indicador/pageable?fim=${this.dataFinal}&inicio=${this.dataInicio}&page=${this.pagination.page - 1}&size=${this.pagination.size}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if (res.data != '') {
            this.totalSize = res.data.size;
            this.indicadores.total_pedidos = res.data.numeroTotalPedidos,
            this.indicadores.pedidos = res.data.numeroPedidos,
            this.indicadores.cotacao_sucesso = res.data.numeroCotacaoSucesso,
            this.indicadores.cotacao_falha = res.data.numeroCotacaoFalha,
            this.indicadores.total_embalamento = res.data.numeroEmbalamento,
            this.indicadores.total_objetos = res.data.numeroObjeto
            this.grid.logs.items = res.data.availableOrder;
            this.grid.logs.itemsAxus = res.data.availableOrder;
            this.grid.logs.porPagina = res.data.availableOrder.length;
            this.logsBkp = res.data.availableOrder;
            for (let i = 0; i < res.data.availableOrder.length; i++) {
              this.grid.logs.items[i].dataVenda = this.grid.logs.items[i].dataVenda? moment(this.grid.logs.items[i].dataVenda).format("DD/MM/YYYY "): "";
              this.grid.logs.items[i].dataPedido = this.grid.logs.items[i].dataPedido? moment(this.grid.logs.items[i].dataPedido).format("DD/MM/YYYY "): "";
            }
            this.refreshGrid = !this.refreshGrid;
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        })
        .finally(() => (this.grid.logs.carregando = false));
    },
    fechar() {
      this.$router.push("/");
    },

    async carregarLogsReprocessar() {
      this.loadingReprocessar = true;
      await axios
        .get(`${baseApiUrlPersys}/order/calcular_frete/force?fim=${this.dataFinal}&inicio=${this.dataInicio}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {
          if(res.status == 200) {
            this.loadingReprocessar = false;
            this.modal.log_pedido = false;
            this.carregarLogs()
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.loadingReprocessar = false;
        })
        .finally(() => this.loadingReprocessar = false);
    },

    async loadLogPedido(item) {

      this.dataModalLogPedido.numeroDoPedido = item.numeroDoPedido;
      this.base_transp = item.base;


      await axios
        .get(
          `${baseApiUrlPersys}/order/${item.numeroDoPedido}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          }
        )
        .then((res) => {

          if (res.data.jsonProdutos == null){
            this.grid.produtos.items = []
          }else{
            this.grid.produtos.items = JSON.parse(res.data.jsonProdutos)
            this.grid.produtos.items.forEach(element => {
              element.altura = Number(element.altura * 100).toFixed(2);
              element.largura = Number(element.largura * 100).toFixed(2);
              element.comprimento = Number(element.comprimento * 100).toFixed(2);
              element.peso = element.peso * 1000;
            });
          }

          if (res.data.jsonSisfreteRequisicao == null){
            this.grid.cotacao.items = []
          }else{
            this.grid.cotacao.items = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.search.items

            this.grid.cotacao.items.forEach(element => {
              element.height = (element.height).toFixed(2);
              element.width = (element.width).toFixed(2);
              element.length = (element.length).toFixed(2);
            });
          }

          if (res.data.jsonSisfreteEmbalamentoRequisicao == null){
            this.grid.produtosEmbalamento.items = []
          }else{
            this.grid.produtosEmbalamento.items = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.search.items

            this.grid.produtosEmbalamento.items.forEach(element => {
              element.height = (element.height).toFixed(2);
              element.width = (element.width).toFixed(2);
              element.length = (element.length).toFixed(2);
            });
          }

          if (res.data.jsonSisfrete == null){
            this.grid.retornoCotacao.items = []
          }else{
            this.grid.retornoCotacao.items = JSON.parse(res.data.jsonSisfrete).data[0]
          }

          if (res.data.jsonSisfreteEmbalamento == null){
            this.grid.retornoEmbalamento.items = []
          }else{
            this.grid.retornoEmbalamento.items = JSON.parse(res.data.jsonSisfreteEmbalamento).data;
          }

          if (res.data == null){
            this.dadosStatus = []
          }else{
            this.dadosStatus = res.data;
          }

          this.dadosStatus.numeroDoPedido = JSON.parse(res.data.json).availableOrderId.numeroDoPedido;

          if(this.dadosStatus.dataEmissaoNota != "" && this.dadosStatus.dataEmissaoNota != null) {
            this.dadosStatus.dataEmissaoNota = moment(this.dadosStatus.dataEmissaoNota).format("DD/MM/YYYY");
          }

          if (res.data.jsonSisfreteRequisicao == null){
            this.dadosCotacao.token = ''
          }else{
            this.dadosCotacao.token = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.code;
          }

          if (res.data.jsonSisfreteRequisicao == null){
            this.dadosCotacao.cepDestino = ''
          }else{
            this.dadosCotacao.cepDestino = JSON.parse(res.data.jsonSisfreteRequisicao).type.proced.search.destination;
          }

          if (res.data.jsonSisfreteEmbalamentoRequisicao == null){
            this.dadosEmbalamento.token = ''
          }else{
            this.dadosEmbalamento.token = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.code;
          }

          if (res.data.jsonSisfreteEmbalamentoRequisicao == null){
            this.dadosEmbalamento.cepDestino = ''
          }else{
            this.dadosEmbalamento.cepDestino = JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao).type.proced.search.destination;
          }

          this.json.cotacao = res.data.jsonSisfreteRequisicao == null ? "Não há dados disponíveis" : JSON.parse(res.data.jsonSisfreteRequisicao);
          this.json.embalamento = res.data.jsonSisfreteEmbalamentoRequisicao == null ? "Não há dados disponíveis" : JSON.parse(res.data.jsonSisfreteEmbalamentoRequisicao);

          this.dataModalLogPedido.jsonProdutos = res.data.jsonProdutos;
          this.dataModalLogPedido.jsonSisfrete = res.data.jsonSisfrete;
          this.dataModalLogPedido.jsonSisfreteRequisicao = res.data.jsonSisfreteRequisicao;
          this.dataModalLogPedido.jsonStatus = res.data.jsonStatus;
          this.dataModalLogPedido.jsonSisfreteEmbalamento = res.data.jsonSisfreteEmbalamento;
          this.dataModalLogPedido.jsonSisfreteEmbalamentoRequisicao = res.data.jsonSisfreteEmbalamentoRequisicao;

          this.dataModalLogPedido.pedidoCanalVenda = res.data.pedidoCanalVenda;
          this.dataModalLogPedido.codigoPedidoAbacos = res.data.codigoPedidoAbacos;
          this.dataModalLogPedido.dataVenda = res.data.dataVenda;
          this.dataModalLogPedido.statusPedido = res.data.statusPedido;
          this.dataModalLogPedido.chaveNFE = res.data.chaveNfe;
          this.dataModalLogPedido.numeroNota = res.data.numeroNota;
          this.dataModalLogPedido.numeroObjeto = res.data.numeroObjeto;
          this.dataModalLogPedido.transportadoraNome = res.data.transportadoraNome;
          this.dataModalLogPedido.servicoEntrega = res.data.servicoEntrega;
          this.dataModalLogPedido.canalVenda = res.data.canalVenda;
          this.dataModalLogPedido.subCanal = res.data.subCanal;
          this.dataModalLogPedido.base = res.data.base;
          this.dataModalLogPedido.codigoServico = res.data.codigoServico;
          this.dataModalLogPedido.valorPedido = res.data.valorPedido;
          this.dataModalLogPedido.valorFrete = res.data.valorFrete;
          this.dataModalLogPedido.mensagem = res.data.mensagem;
          this.dataModalLogPedido.destinatarioCep = res.data.destinatarioCep;

        }).catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
        }).finally(() => this.modal.log_pedido = true);
    },
    // itemStyle(item) {
    //   return item.Base == this.base_transp ? 'itemGridGreenAndBold' : '';
    // },
    closeModalLogPedido() {
      this.dataModalLogPedido.numeroDoPedido = "";
      this.dataModalLogPedido.pedidoCanalVenda = "";
      this.dataModalLogPedido.codigoPedidoAbacos = "";
      this.dataModalLogPedido.dataVenda = "";
      this.dataModalLogPedido.statusPedido = "";
      this.dataModalLogPedido.chaveNFE = "";
      this.dataModalLogPedido.numeroNota = "";
      this.dataModalLogPedido.numeroObjeto = "";
      this.dataModalLogPedido.transportadoraNome = "";
      this.dataModalLogPedido.servicoEntrega = "";
      this.dataModalLogPedido.canalVenda = "";
      this.dataModalLogPedido.subCanal = "";
      this.dataModalLogPedido.base = "";
      this.dataModalLogPedido.codigoServico = "";
      this.dataModalLogPedido.valorPedido = "";
      this.dataModalLogPedido.valorFrete = "";
      this.dataModalLogPedido.mensagem = "";
      this.dataModalLogPedido.jsonProdutos = "";
      this.dataModalLogPedido.jsonSisfreteRequisicao = "";
      this.dataModalLogPedido.jsonSisfrete = "";
      this.dataModalLogPedido.jsonSisfreteEmbalamentoRequisicao = "";
      this.dataModalLogPedido.jsonSisfreteEmbalamento = "";
      this.dataModalLogPedido.jsonStatus = "";
      this.modal.log_pedido = false;
    },
    async reprocessar() {
      this.loadingReprocessar = true;
      await axios
        .get(`${baseApiUrlPersys}/order/calcular_frete/force/numero_pedido/${this.dataModalLogPedido.numeroDoPedido}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          })
        .then((res) => {
          if(res.status == 200) {
            this.loadingReprocessar = false;
            this.modal.log_pedido = false;
            this.carregarLogs()
            showSuccess("Pedido reprocessado com sucesso!");
          } else {
            showError("Pedido não reprocessado!");
          }
        }).catch(() => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.loadingReprocessar = false;
        });
    },
    changePage(page) {
      this.pagination.page = page;
      this.carregarLogs();
    },
    async reprocessAll() {
      this.loadingReprocessar = true;
      await axios
        .get(`${baseApiUrlPersys}/order/calcular_frete/force?fim=${this.dataFinal}&inicio=${this.dataInicio}`, {
            headers: {
              'Token-API': this.$store.state.token_persys
            }
          })
        .then((res) => {
          if(res.status == 200) {
            this.loadingReprocessar = false;
            this.carregarLogs();
            showSuccess("Pedidos reprocessado com sucesso!");
          } else {
            this.loadingReprocessar = false;
            showError("Pedidos não reprocessados!");
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
            icon: 'warning',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#4BB543',
            allowOutsideClick: true,
          });
          this.loadingReprocessar = false;
        })
    },
    deleteAll() {
      this.$swal({
        title: 'Confirmação',
        html: `Confirma a exclusão?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let numeroPedido = [];
          this.selected.forEach(element => {
            numeroPedido.push(element.numeroDoPedido);
          });
          await axios
            .delete(`${baseApiUrlPersys}/order`, {
              data: {
                numeroPedido: numeroPedido
              },
              headers: {
                'Token-API': this.$store.state.token_persys
              }
            })
            .then((res) => {
              if(res.status == 200) {
                this.$swal({
                  title: 'Sucesso!!!',
                  text: 'Itens excluídos com sucesso.',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#4BB543',
                  allowOutsideClick: true,
                });
                this.selected = [];
                this.carregarLogs();
              }
            }).catch(() => {
              this.$swal({
                title: 'Alerta',
                text: 'Sistema temporariamente indisponível ! Tente novamente mais tarde.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#4BB543',
                allowOutsideClick: true,
              });
            });
        }
      });
      },
    async alterarTransportadoraServicoEntrega(item) {
      await this.consultaTransportadorasServicosEntrega();
      this.dadosModal.numeroDoPedido = item.numeroDoPedido;
      this.dadosModal.codigoPedidoAbacos = item.codigoPedidoAbacos;
      this.dadosModal.transportadoraNome = item.transportadoraNome;
      this.dadosModal.servicoEntrega = item.servicoEntrega;
      this.dadosModal.base = item.base;
      this.dadosModal.codigoTransportadoraSisfrete = item.codigoTransportadoraSisfrete;
      this.transportadoraServicoEntregaSelecionado = {};
      this.modalAlterarTransportadoraServico = true;
    },
    async gravarTransportadoraServicoEntrega() {
      await axios
        .put(`${baseApiUrlPersys}/order/${this.dadosModal.numeroDoPedido}`, {
          transportadoraNome: this.dadosModal.transportadoraNome,
          servicoEntrega: this.dadosModal.servicoEntrega.trim() != "" ? this.dadosModal.servicoEntrega : null,
          base: this.dadosModal.base,
          codigoTransportadoraSisfrete: this.dadosModal.codigoTransportadoraSisfrete,
        }, {
          headers: {
            'Token-API': this.$store.state.token_persys
          }
        })
        .then(async (response) => {
          if(response.status == 200) {
            await axios
              .get(
                `${baseApiUrlPersys}/order/calcular_frete/force/numero_pedido/${this.dadosModal.numeroDoPedido}`, {
                  headers: {
                    'Token-API': this.$store.state.token_persys
                  }
                }
              )
              .then(async (response) => {
                if(response.status == 200) {
                  this.$swal({
                    title: 'Sucesso!!!',
                    text: 'Informações editadas com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#4BB543',
                    allowOutsideClick: true,
                  });
                  this.modalAlterarTransportadoraServico = false;
                  await this.carregarLogs();
                } else {
                  this.$swal({
                    title: 'Alerta',
                    text: 'Não foi possível alterar as informações. Tente novamente mais tarde !!!',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#62C6A2',
                    allowOutsideClick: false,
                  });
                }
              })
              .catch((error) => {
                this.$swal({
                  title: 'Alerta',
                  text: 'Não foi possível alterar as informações. Tente novamente mais tarde !!!',
                  icon: 'warning',
                  confirmButtonText: 'OK',
                  confirmButtonColor: '#62C6A2',
                  allowOutsideClick: false,
                });
              });
          } else {
            this.$swal({
              title: 'Alerta',
              text: 'Não foi possível alterar as informações. Tente novamente mais tarde !!!',
              icon: 'warning',
              confirmButtonText: 'OK',
              confirmButtonColor: '#62C6A2',
              allowOutsideClick: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: 'Alerta',
            text: 'Não foi possível alterar as informações. Tente novamente mais tarde !!!',
            icon: 'warning',
            confirmButtonText: 'OK',
            confirmButtonColor: '#62C6A2',
            allowOutsideClick: false,
          });
        });
    },
    async consultaTransportadorasServicosEntrega() {
      await axios.get(`${baseApiUrlPersys}/transportadora/empresa`, {
          headers: {
            'Token-API': this.$store.state.token_persys
          }
        }
      )
      .then(res => {
        if(res.data.length > 0) {
          this.transportadoraServicoEntrega = [];
          let i = 0;
          res.data.forEach(element => {
            if (!element.ignorarIntegracao) {
              let obj = {
                descricao: element.nome + ' - ' + element.servicoEntrega,
                codigo: i,
                transportadora: element.nome,
                servicoEntrega: element.servicoEntrega,
                base: element.base,
                codigoSisfrete: element.codigoSisfrete
              }
              i++;
              this.transportadoraServicoEntrega.push(obj);
            }
          });
        }
      });
    },
    changeTransportadoraServicoEntrega() {
      this.dadosModal.transportadoraNome = this.transportadoraServicoEntrega[this.transportadoraServicoEntregaSelecionado].transportadora;
      this.dadosModal.servicoEntrega = this.transportadoraServicoEntrega[this.transportadoraServicoEntregaSelecionado].servicoEntrega;
      this.dadosModal.base = this.transportadoraServicoEntrega[this.transportadoraServicoEntregaSelecionado].base;
      this.dadosModal.codigoTransportadoraSisfrete = this.transportadoraServicoEntrega[this.transportadoraServicoEntregaSelecionado].codigoSisfrete;
    }
  },
};
</script>

<style scoped>
.alinhamento {
  padding-bottom: 0 !important;
  padding-top: 25px !important;
}
/* .itemGridGreenAndBold {
  color: green;
  font-weight: bold;
} */

.my-text-field .v-text-field__details {
  display: none;
}
</style>